import React from 'react';
import './Message.css'; 

function SearchMessage({roomID}) {


    var note = 'Searching for other side...';
    return (
        <div className={`message message-note`}>
            <div className="message-note-text">
                {note}
            </div>
        </div>
    );
}



export default SearchMessage;
