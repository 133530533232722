import React from 'react';
import './Message.css'; 

import {fetchSuggestion, fetchSuggestionMessages} from '../firebase.js';

function StartMessage({isMyTurn, setInputValue, suggestCounter, setSuggestCounter}) {

    const [messages, setMessages] = React.useState([]);

    React.useEffect(() => {
        async function fetchData() {
            const messages = await fetchSuggestionMessages();
            setMessages(messages);
        }
        fetchData();
    }, []);

    function getSuggestMessage(num){
        return messages[num % messages.length];
    }


    var note = isMyTurn ? 'You start the conversation' : 'Other side starts the conversation';
    var buttonText = 'Suggest opener'
    var hint = 'Hint: Suggestion was pasted to input field down below';
    if(!isMyTurn)
        return (
            <div className={`message message-note`}>
                <div className="message-note-text">
                    {note}
                </div>
            </div>
        );
    else
        return (
            <div className={`messages-list-item`}>
                <div className={`message message-note`}>
                    <div className="message-note-text">
                        {note}
                    </div>
                </div>
                <div className={`message message-note`}>
                    <div className="message-note-text">
                        {<button className="message-decide-button"  onClick={async () => {setSuggestCounter(suggestCounter + 1); setInputValue(getSuggestMessage(suggestCounter));}}>{buttonText}</button>}
                        {suggestCounter > messages.length? (
                        <div className="message-note-text">
                            {hint}
                        </div>) : null}
                    </div>
                </div>
            </div>
        );
}
export default StartMessage;