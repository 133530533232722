import React, { useEffect } from 'react';

function AdSenseAd() {

  
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);


  return (
    <div style={{ margin: 'auto', width: '200px', height: '600px'}}>
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-1694106185155655"
        data-ad-slot="8513599666"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
}

export default AdSenseAd;
