import React from 'react';
import './Home.css'; 

function Note({ meStart }) {

    return null;

    const wert = localStorage.getItem('hideNote');

    if (wert === 'true') {
        return null;
    }

    var note = 'Notes:\n• You start the conversation\n• Be respectful, you might chat with a human';
    return (
        <div className={`message message-note`}>
            <div className="message-note-text">
                {note}
            </div>
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            <div className="message-note-button">
                <button onClick={() => localStorage.setItem('hideNote', 'true')}>Don't show anymore</button>
            </div>
            </div>
        </div>
    );
}



export default Note;
