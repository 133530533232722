import React, { useEffect } from 'react';
import './Message.css';

import botRed from '../botRed.png';
import humanGreen from '../humanGreen.png';

function OtherResultMessage({otherDecided, isOtherPlayer, meDecidedTimeout, otherDecidedTimeout, leaveOrAbort}) {

    var note = '';

    if (leaveOrAbort)
        note = 'Other side left!\nNo result';
    else if(otherDecidedTimeout)
        note = 'Other side did not respond in time';
    else if (meDecidedTimeout)
        note = 'You did not respond in time';
    else if(!otherDecided)
        note = 'Other side is thinking...';
    else if (isOtherPlayer && otherDecided == 'human')
        note = 'Other side wins!\nHe chose';
    else
        note = 'Other side looses!\nHe chose';

    function getImage() {
        if (isOtherPlayer && otherDecided == 'human')
            return humanGreen;
        else
            return botRed;
    }

    function getColor() {
        if (isOtherPlayer && otherDecided == 'human')
            return '#00ff00';
        else
            return '#ff0000';
    }


    /* from react native:
        return (
        <View style={styles.messageNote}>
            <View style={styles.resultContainer}>
                <Text style={styles.messageNoteText}>{note}</Text>
                {otherDecided ? ( <View style={{alignItems: 'center'}}>
                        <View style={styles.resultImageContainer}>
                            <Image source={getImage()} style={styles.iconImage} />
                        </View>
                        <Text style={{ ...styles.resultImageText, color: getColor() }}>{otherDecided ? otherDecided.toUpperCase() : ''}</Text>
                    </View>
                ) : null}
            </View>
        </View>
    );
    */

    return (
        <div className={`message message-note`}>
            <div className="result-container">
                <div className="message-note-text">
                    {note}
                </div>
                {otherDecided && !meDecidedTimeout && !otherDecidedTimeout ? ( <div style={{alignItems:'center'}}>
                        <img src={getImage()} className='icon-image' />
                        <div className='result-image-text' style={{color: getColor()}}>{otherDecided.toUpperCase()}</div>
                    </div>
                ) : null}
            </div>
        </div>
    );



    /*return (
        <div className={`message message-note`}>
            <div className="message-note-text">
                {note}
            </div>
        </div>
    );*/
}



export default OtherResultMessage;
