import React from 'react';
import './Message.css'; 

function LeaveMessage({isMyTurn}) {

    var note = isMyTurn ? 'You left the game' : 'Other side left the game';
    return (
        <div className={`message message-note`}>
            <div className="message-note-text">
                {note}
            </div>
        </div>
    );
}



export default LeaveMessage;
