import React from 'react';
import './Message.css';

import botRed from '../botRed.png';
import botGreen from '../botGreen.png';
import humanRed from '../humanRed.png';
import humanGreen from '../humanGreen.png';

function MyResultMessage({meDecided, isOtherPlayer}) {

    var note = '';
    if (isOtherPlayer && meDecided == 'human')
        note = 'You win!\nYou chose';
    else if(isOtherPlayer && meDecided == 'bot')
        note = 'You lose!\nYou chose';
    else if(!isOtherPlayer && meDecided == 'human')
        note = 'You lose!\nYou chose';
    else if(!isOtherPlayer && meDecided == 'bot')
        note = 'You win!\nYou chose';

    function getImage() {
        if (isOtherPlayer && meDecided == 'human')
            return humanGreen;
        else if(isOtherPlayer && meDecided == 'bot')
            return botRed;
        else if(!isOtherPlayer && meDecided == 'human')
            return humanRed;
        else if(!isOtherPlayer && meDecided == 'bot')
            return botGreen;
    }

    function getColor() {
        if (isOtherPlayer && meDecided == 'human')
            return '#00ff00';
        else if(isOtherPlayer && meDecided == 'bot')
            return '#ff0000';
        else if(!isOtherPlayer && meDecided == 'human')
            return '#ff0000';
        else if(!isOtherPlayer && meDecided == 'bot')
            return '#00ff00';
    }

    return (
        <div className={`message message-note`}>
            <div className="result-container">
                <div className="message-note-text">
                    {note}
                </div>
                <img src={getImage()} className='icon-image' />
                <div className='result-image-text' style={{color: getColor()}}>{meDecided.toUpperCase()}</div>
            </div>
        </div>
    );
}

export default MyResultMessage;
