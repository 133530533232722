// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore, doc, onSnapshot, arrayUnion, updateDoc, getDoc } from "firebase/firestore";

import { CensorBadWords } from './CensorBadWords.js';

import { isLabelWithInternallyDisabledControl } from "@testing-library/user-event/dist/utils";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
/*const firebaseConfig = {
    apiKey: "AIzaSyBq__5zWgZDt_bsvOYFXanbTt-Q-noTlUw",
    authDomain: "human-or-bot-854ab.firebaseapp.com",
    projectId: "human-or-bot-854ab",
    storageBucket: "human-or-bot-854ab.appspot.com",
    messagingSenderId: "146369198503",
    appId: "1:146369198503:web:fa096328c618512b6ede82",
    measurementId: "G-Y9D1TJ5RLP"
};*/
const firebaseConfig = {
    apiKey: "AIzaSyAejdTpdt6d540R7x3SA_13KJ6drbBZd7Q",
    authDomain: "humanorbot-cd704.firebaseapp.com",
    projectId: "humanorbot-cd704",
    storageBucket: "humanorbot-cd704.appspot.com",
    messagingSenderId: "733700487078",
    appId: "1:733700487078:web:3d6b2bc359967f082ec167"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


export async function fetchAIMessage() {
    const functions = getFunctions();
    const getAIMessage = httpsCallable(functions, 'aiMessage');
    const result = await getAIMessage();
    //console.log(result.data);
    return result.data;
}


export async function fetchRoom(setRoomID, setMessages, setIsMyTurn, userID, setIsOtherPlayer, setMeDecided, setOtherDecided, setStartGame, setAbort, setRetrySearch) {
        const functions = getFunctions();
        const matchMaking = httpsCallable(functions, 'matchMaking');
        //console.log(userID);
        const payload = {
            userID: userID
        };
        const result = await matchMaking(payload);
        const roomID = result.data.data;
        const isAiRoom = result.data.ai;
        //console.log('fetchRoom')
        //const roomID = await matchMaking(userID);
        //console.log('roomID: ' + roomID, 'isAiRoom: ' + isAiRoom);
        //return;
        setRoomID(roomID);
        /*return result.data;
 
        return fetchMessageMock();*/
        var firebaseDoc = roomID;
        var firebaseCollection = isAiRoom ? 'aiRooms' : 'rooms';
        //listen to changes in the collection
        const unsub = onSnapshot(doc(db, firebaseCollection, firebaseDoc), async (document) => {
            //console.log('data', document.data());
            if(!document.data().startGame && document.data().userB != null ){
                //console.log('start game');
                if(document.data().userA == userID || document.data().userA == 'AI' || document.data().userB == 'AI'){
                    //console.log("update StartGame true");
                    await updateDoc(doc(db, firebaseCollection, firebaseDoc), {
                        startGame: true,
                        startTime: Date.now()
                    });
                }
                /*else{
                    async function timeoutRetry() {
                        await new Promise(r => setTimeout(r, 2000));
                        
                        const docSnap = await getDoc(doc(db, firebaseCollection, firebaseDoc));
    
                        if (!docSnap.data().startGame) {
                            //console.log('setRetrySearch - unsub()');
                            setRetrySearch(true);
                            unsub();
                        }
                    };
                    timeoutRetry();
                    return;
                }*/
            }

            if(document.data().abort == true){
                //console.log('abort - unsub()');
                setAbort(true);
                unsub();
                return;
            }

            if(document.data().startGame){
                setStartGame(true);
            }

            //console.log("Current data(ME): ", document.data());
            var messages = document.data().messages;

            if(messages.length > 0 && messages[messages.length - 1].waitTime != null 
                && document.data().userASelected == null && document.data().userBSelected == null){
                var waitTime = messages[messages.length - 1].waitTime;
                if(waitTime > 20000)
                    waitTime = 20000;
    
                //console.log('waitTime: ' + waitTime);
                var lastMessageTime= messages.length == 1 ? document.data().startTime : messages[messages.length - 2].timestamp;
                var calcedWaitTime = waitTime - (Date.now() - lastMessageTime);
    
                //console.log('calcedWaitTime: ' + calcedWaitTime);
    
                if(Date.now() + calcedWaitTime - (document.data().startTime + 120000) > 0){
                    //console.log('AI MESSAGE TAKES TO LONG - unsub()');
                    unsub();
                    return;
                }
                if(calcedWaitTime > 0)
                    await new Promise(r => setTimeout(r, calcedWaitTime));
            }

            if(messages.length != 0){

                var allowCensoring = await localStorage.getItem('allowCensoring') || null;

                var censoredMessages = messages.map(message => {
                    return {
                      ...message, 
                      content: allowCensoring == 'true' ? CensorBadWords(message.content) : message.content
                    };
                  });
                setMessages(censoredMessages);
            }
                
            setIsOtherPlayer(document.data().userA != 'AI' && document.data().userB != 'AI');
            setIsMyTurn(document.data().userA == userID && messages.length % 2 == 0 || document.data().userB == userID && messages.length % 2 == 1);

            if(document.data().userA == userID){
                setMeDecided(document.data().userASelected);
                setOtherDecided(document.data().userBSelected);
            }
            else{
                setMeDecided(document.data().userBSelected);
                setOtherDecided(document.data().userASelected);
            }

            if(document.data().userBSelected != null && document.data().userASelected != null ||
                document.data().userA == 'AI' && document.data().userBSelected != null ||
                document.data().userB == 'AI' && document.data().userASelected != null)
            {
                //console.log('ussub');
                unsub();
            }
        });

        async function timeoutRetry() {
            await new Promise(r => setTimeout(r, 5000));
            
            const docSnap = await getDoc(doc(db, firebaseCollection, firebaseDoc));

            if (!docSnap.data().startGame) {
                //console.log('setRetrySearch - unsub()');
                setRetrySearch(true);
                unsub();
            }
        };
        timeoutRetry();

        //unsub() after 3 minutes
        setTimeout(() => {
            //console.log('ussub() - timeout');
            unsub();
        }, 180000);



        //toDo: move to server
        /*if (Math.random() < -0.5)
            simulateAIStartMessage(roomID);*/

}

export async function sendMessage(firestoreID, message, isAi){
    //console.log("Sending message: ", message);
    var firebaseDoc = firestoreID;
    var firebaseCollection = isAi ? 'aiRooms' : 'rooms';
    const docRef = doc(db, firebaseCollection, firebaseDoc);

    await updateDoc(docRef, {
        messages: arrayUnion(message),
        web: true
    });

    //if(message.userID != 0)
    //    simulateAI(firestoreID);
}

export async function sendSelected(firestoreID, userID, selected, isAi){

    try{
        //console.log("Sending selected: ", selected);
        var firebaseDoc = firestoreID;
        var firebaseCollection = isAi ? 'aiRooms' : 'rooms';
        const docRef = doc(db, firebaseCollection, firebaseDoc);
        const fetchedUserIDA = await getDoc(docRef).then((doc) => {
            if (doc.exists()) {
                //console.log("Document data:", doc.data());
                return doc.data().userA;
            } else {
                // doc.data() will be undefined in this case
                //console.log("No such document!");
            }
        });

        //console.log(userID, fetchedUserIDA)

        if(userID == fetchedUserIDA)
            await updateDoc(docRef, {
                userASelected: selected
            });
        else
            await updateDoc(docRef, {
                userBSelected: selected
            });
    }
    catch(e){
        //console.log(e);
    }
}

export async function sendLeave(firestoreID, isAi){
    //console.log("Sending leave ", isAi);
    var firebaseDoc = firestoreID;
    var firebaseCollection = isAi ? 'aiRooms' : 'rooms';
    const docRef = doc(db, firebaseCollection, firebaseDoc);

    await updateDoc(docRef, {
        abort: true
    });
}

export async function fetchSettings() {
    const docRef = doc(db, 'settings', 'data');

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        //console.log("Document data:", docSnap.data());
        return docSnap.data();
    }
    else {
        // doc.data() will be undefined in this case
        //console.log("No such document!");
    }
}

export async function sendReport(firestoreID, isAi, userID) {
    const firebaseCollection = isAi ? 'aiRooms' : 'rooms';

    try {
        const documentRef = doc(db, firebaseCollection, firestoreID);
        
        await updateDoc(documentRef, {
            reportFrom: arrayUnion(userID)
        });
    } catch (error) {
        console.error("Error - Timelimit:", error);
    }
}

export async function fetchSuggestion() {

    const functions = getFunctions();
    const fetchSuggestion = httpsCallable(functions, 'fetchSuggestion');
    const result = await fetchSuggestion();
    const data = result.data;
    return data;
}

export async function fetchSuggestionMessages() {

    const functions = getFunctions();
    const fetchSuggestionMessages = httpsCallable(functions, 'fetchSuggestionMessages');
    const result = await fetchSuggestionMessages();
    const data = result.data;
    return data;
}


export default app;