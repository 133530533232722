import BAD_WORDS from "./BadWords";

export function CensorBadWords(message) {
    
    let worte = message.split(' ');
    let zensierteNachricht = worte.map(wort => {
        // Prüfen, ob das Wort in der Liste der Schimpfwörter ist
        if (BAD_WORDS.includes(wort.toLowerCase())) {
            // Ersetzen des Wortes durch den ersten Buchstaben und Sterne
            return wort.charAt(0) + '*'.repeat(wort.length - 1);
        }
        return wort;
    });
    return zensierteNachricht.join(' ');
}