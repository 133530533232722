import React, { useState, useEffect, useRef } from 'react';
import Message from './Messages/Message';
import { fetchRoom, sendLeave, sendMessage, simulateAI } from './firebase.js';
import Header from './Header';
import { LinearProgress } from '@mui/material';
import Note from './Note';
import LeaveMessage from './Messages/LeaveMessage';
import OtherResultMessage from './Messages/OtherResultMessage';
import MyResultMessage from './Messages/MyResultMessage';
import NewGameMessage from './Messages/NewGameMessage';

import './Home.css';
import './Chat.css';
import DecideMessage from './Messages/DecideMessage';
import SearchMessage from './Messages/SearchMessage';
import StartMessage from './Messages/StartMessage';


function Chat({ messages, setMessages, handleSendMessage, started, setStarted, roomID, userID, isMyTurn, isOtherPlayer, meDecided, otherDecided, newGame, startGame, abort, setMeDecided }) {
    const [inputValue, setInputValue] = useState("");
    const [progress, setProgress] = useState(0);
    const [progressFinishTime, setProgressFinishTime] = useState(null);
    const [conversationProgress, setConversationProgress] = useState(0);
    const [leave, setLeave] = useState(false);
    const [finished, setFinished] = useState(false);
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [otherDecidedTimeout, setOtherDecidedTimeout] = useState(false);
    const [meDecidedTimeout, setMeDecidedTimeout] = useState(false);
    const [suggestCounter, setSuggestCounter] = useState(0);

    useEffect(() => {
        
        if (started && startGame && otherDecided == null && meDecided == null || finished || finished && !isOtherPlayer && meDecided != null || leave || abort) {
            var finishTime;
            var calcProgess;
            //console.log("Renew Progress (useEffect)");
            if(finished && !isOtherPlayer && meDecided != null || leave || abort){
                finishTime = Date.now();
                calcProgess = 100;
            }
            else if(((otherDecided == null && meDecided != null) || (otherDecided != null && meDecided == null)) && finished ){
                finishTime = progressFinishTime;
                calcProgess = 100 - (((finishTime - Date.now()) / 20000) * 100)
                //console.log("Finished", finishTime, calcProgess);
            }
            else{
                finishTime = otherDecided != null && meDecided != null ? Date.now() : Date.now() + 20000;
                calcProgess = otherDecided != null && meDecided != null ? 100 : 0;
            }
            setProgressFinishTime(finishTime);
            setProgress(calcProgess);
            updateScroll();

            const interval = setInterval(() => {
                const now = Date.now();
                const elapsed = finishTime - now;
                const totalDuration = 20000; // 20 seconds
                const newProgress = 100 - ((elapsed / totalDuration) * 100);
                ////console.log(now, finishTime, elapsed, totalDuration, newProgress,Math.min(newProgress, 100));
                setProgress(newProgress, 100);
            }, 200);
    
            return () => {
                clearInterval(interval);
            };
        }

        
    }, [messages.length, startGame, finished, otherDecided, meDecided, leave]);
  
    useEffect(() => {
        if(started && startGame) {
            //console.log("Renew Conversation (useEffect)");
            const conversationDuration = 120000;
            const finishTime = Date.now() + conversationDuration;
            setConversationProgress(0);
            setProgress(0);
            
            const interval = setInterval(() => {
                const now = Date.now();
                const elapsed = finishTime - now;
                const totalDuration = conversationDuration;
                const newProgress = 100 - ((elapsed / totalDuration) * 100);
                ////console.log(now, finishTime, elapsed, totalDuration, newProgress,Math.min(newProgress, 100));
                setConversationProgress(Math.min(newProgress, 100));
            }, 200);

            return () => {
                clearInterval(interval);
            };
        }
    }, [started, startGame]);

    useEffect(() => {
        /*if(progress >= 50 && !finished && isMyTurn)
            handleSendMessage('Tell me another joke', setInputValue);*/
        
        if(progress >= 100 && !finished) {
            if(isMyTurn && inputValue !== "" && !leave && !finished) 
                handleSendMessage(inputValue, setInputValue);
            else if(progressFinishTime - Date.now() <= -5000 && !leave && !finished ||
                    isMyTurn && inputValue === "" && !leave && !finished) {
                //console.log('Force leave')
                setLeave(true);
                sendLeave(roomID, !isOtherPlayer);
            }
        }
        if( !otherDecidedTimeout && otherDecided == null && progress >= 100 && finished && meDecided != null){
            setOtherDecidedTimeout(true);
        }
        if( !meDecidedTimeout && otherDecided != null && progress >= 100 && finished && meDecided == null){
            setMeDecidedTimeout(true);
        }
    }, [progress]);

    useEffect(() => {
        if(leave || abort) {
            if(conversationProgress >= 50)
                setFinished(true)
            setConversationProgress(0);
            setProgress(100);
            setStarted(false);
        }
    }, [leave, abort]);

    useEffect(() => {
        if(started && startGame) {
            setProgress(0);
        }
        if(started && !startGame && searchTimeout == null){
            setSearchTimeout(2000);
            const timer = setInterval(() => {
                setSearchTimeout((prevProgress) => (prevProgress <= 0 ? 0 : prevProgress - 1000));
                if(started && startGame && searchTimeout <= 0){
                    clearInterval(timer);
                    //console.log("Start Game from useEffect");
                    startNewGame();
                }

            }, 1000);
            return () => {
                clearInterval(timer);
            }
        }
    }, [started, startGame]);
            
    useEffect(() => {
        if(conversationProgress != null && conversationProgress >= 100 && !leave) {
            setFinished(true);
            setProgress(100);
        }
    }, [conversationProgress]);

    const showLeaveMessage = () => {
        if(leave || abort) {
            return <LeaveMessage isMyTurn={isMyTurn}></LeaveMessage>
        }
    }

    const showDecideMessage = () => {
        if(finished && meDecided == null && (leave && !isMyTurn || !leave) ) {
            return <DecideMessage roomID={roomID} userID={userID} setMeDecided={setMeDecided} isOtherPlayer={isOtherPlayer} leaveOrAbort={leave || abort}></DecideMessage>
        }
    }

    const showMyResultMessage = () => {
        if(finished && meDecided) 
            return <MyResultMessage meDecided={meDecided} isOtherPlayer={isOtherPlayer}></MyResultMessage>
    }

    const showOtherResultMessage = () => {
        if(finished && meDecided) 
            return <OtherResultMessage otherDecided={otherDecided} isOtherPlayer={isOtherPlayer} meDecidedTimeout={meDecidedTimeout} otherDecidedTimeout={otherDecidedTimeout} leaveOrAbort={leave || abort}></OtherResultMessage>
    }

    const showResultMessage = () => {
        if((finished || leave) && meDecided) {
            if(isOtherPlayer)
                return (
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between",}}>
                        <div style={{width: "50%", paddingRight:'2px', display: 'flex', justifyContent: 'center'}}>
                            {showOtherResultMessage()}
                        </div>
                        <div style={{width: "50%",  paddingLeft:'2px', display: 'flex', justifyContent: 'center'}}>
                            {showMyResultMessage()}
                        </div>
                    </div>
                )
            else
                return (
                    <div style={{display: "flex",justifyContent: "center", display: 'flex', justifyContent: 'center'}}>
                        <div style={{paddingLeft:'2px'}}>
                            {showMyResultMessage()}
                        </div>
                    </div>
                )


        }
    }

    const showNewGameMessage = () => {
        if(finished && meDecided || abort || leave)
            return <NewGameMessage startNewGame={startNewGame} finished={finished} leaveOrAbort={leave || abort}></NewGameMessage>
    }

    const showSearchMessage = () => {
        if((!started || !startGame ) && !abort) {
            return <SearchMessage></SearchMessage>
        }
    }

    const showStartMessage = () => {
        if(started && startGame && messages.length === 0) {
            return <StartMessage isMyTurn={isMyTurn} setInputValue={setInputValue} suggestCounter={suggestCounter} setSuggestCounter={setSuggestCounter}></StartMessage>
        }
    }

    const startNewGame = () => {
        setSuggestCounter(0);
        setFinished(false);
        setLeave(false);
        setStarted(false);
        setProgress(0);
        setConversationProgress(0);   
        setMessages([]);
        setInputValue("");
        setOtherDecidedTimeout(false);
        setMeDecidedTimeout(false);
        //console.log('startNewGame()')
        newGame();
    }

    function updateScroll(){
        var element = document.getElementById("chat");
        element.scrollTop = element.scrollHeight;
    }

    function disallowUserInput(){

        var lastMessageMine = messages && messages.length > 0 ? messages[messages.length - 1].userID == userID : false;
        return !isMyTurn || finished || leave || !started || ((!started || !startGame ) && !abort || lastMessageMine);
    }

    const showProgressBar = () => {

        var lastMessageMine = messages && messages.length > 0 ? messages[messages.length - 1].userID == userID : false;
        if(( !started || !startGame )&& !abort && !leave)
            return <LinearProgress value={Math.min(progress, 100)} sx={{
                backgroundColor: 'rgb(27, 27, 27)', 
                '& .MuiLinearProgress-bar': {
                    backgroundColor: 'white'
                }
            }} />

        if(finished || leave || abort)
            return <LinearProgress variant="determinate" value={Math.min(progress, 100)} sx={{
                backgroundColor: 'rgb(27, 27, 27)', 
                '& .MuiLinearProgress-bar': {
                    backgroundColor: 'white'
                }
            }}/>

        if(isMyTurn && !lastMessageMine)
            return <LinearProgress variant="determinate" value={Math.min(progress, 100)} sx={{
                backgroundColor: 'rgb(27, 27, 27)', 
                '& .MuiLinearProgress-bar': {
                    backgroundColor: 'rgb(54, 211, 94)'
                }
            }}/>
        else
            return <LinearProgress variant="determinate" value={Math.min(progress, 100)} sx={{
                backgroundColor: 'rgb(27, 27, 27)', 
                '& .MuiLinearProgress-bar': {
                    backgroundColor: 'white'
                }
            }}/>
    }

  return (
        <div className="chat-container">
            <Header conversationProgress={Math.min(conversationProgress, 100)} roomID={roomID} isOtherPlayer={isOtherPlayer} userID={userID} sendLeave={sendLeave} started={started}/>
            <div className="messages-list" id='chat'>
                <div className="message-note">
                    <Note meStart={true}/>
                </div>
                <div className="messages-list-item">
                    {showStartMessage()}
                </div>
                <div className="messages-list-item">
                    {messages.map((message, index) => (
                    <Message key={index} content={message.content} msgType={message.userID === userID ? 'me' : 'other'} />
                    ))}
                </div>
                <div className="messages-list-item">
                    {showLeaveMessage()}
                </div>
                <div className="messages-list-item">
                    {showDecideMessage()}
                </div>
                <div className="messages-list-item">
                    {showResultMessage()}
                </div>
                <div className="messages-list-item">
                    {showNewGameMessage()}
                </div>
                <div className="messages-list-item">
                    {showSearchMessage()}
                </div>
            </div>
            <div>
                {showProgressBar()}
                </div>
            <div className="input-container">
                <input 
                className='input-message'
                value={inputValue}
                onKeyDown={(e) => {if(e.key === 'Enter' && inputValue.length > 0 && !disallowUserInput()) {handleSendMessage(inputValue, setInputValue); setProgress(0)}}}
                onChange={(e) => setInputValue(e.target.value)} 
                placeholder={(finished || abort || leave || !startGame) ? "" : ( disallowUserInput() ? "The other side is typing..." : "Type message..." )}
                />
                <button className='SendButton' disabled={disallowUserInput()} style={{backgroundColor: disallowUserInput() ? 'grey' : 'white'}} 
                    onClick={() => { handleSendMessage(inputValue, setInputValue); setProgress(0) }}>Send</button>
            </div>
        </div>

  );
}


export default Chat;
