import React from 'react';
import './Message.css'; 

function Message({ content, msgType }) {

    var msgStyle = '';
    switch (msgType) {
        case 'me':
            msgStyle = 'message-me';
            break;
        default:
            msgStyle = 'message-other';
            break;
    }

    return (
        <div className={`message ${msgStyle}`}>
            {content}
        </div>
    );
}

export default Message;
