import { fetchRoom, sendMessage, fetchSettings } from './firebase.js';
import './Home.css';
import React, { useState, useEffect } from 'react';
import Chat from './Chat.js';

import TypingText from './TypingText';

import { CensorBadWords } from './CensorBadWords.js';

import PropellerAds from './PropellerAds';

import AdSenseAd from './AdSenseAd.js';

import AppleBadge from './appleBadge.png';

const questionTitle = 'Personalize your experience';
const questionText = `To continue offering Human or Bot at no cost to you, we utilize AdMob for advertising solutions. Our objective is to curate an app experience that remains both intuitive and relevant.

By providing your consent, you allow us to display personalized advertisements based on your interactions within our app. This aims to present you with content more in line with your interests. Please be aware that once this preference is set, it can only be changed by clearing the app data.`;



function Home() {

  const [roomID, setRoomID] = useState('');
  const [messages, setMessages] = useState([]);
  const [started, setStarted] = useState(false);
  const [userID, setUserID] = useState(Math.floor(Math.random() * 10000000));
  const [isMyTurn, setIsMyTurn] = useState(false);
  const [isOtherPlayer, setIsOtherPlayer] = useState(false);
  const [meDecided, setMeDecided] = useState(null);
  const [otherDecided, setOtherDecided] = useState(null);
  const [enterChat, setEnterChat] = useState(false);
  const [startGame, setStartGame] = useState(false);
  const [abort, setAbort] = useState(false);
  const [retrySearch, setRetrySearch] = useState(false);
  const [selectedMode, setSelectedMode] = useState("portrait");
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [testValue, setTestValue] = useState('TESTVALUE');
  const [globalMessage, setGlobalMessage] = useState('');
  const [showPersonalizedAdsQuestion, setShowPersonalizedAdsQuestion] = useState(false);
  const [allowPersonalizedAds, setAllowPersonalizedAds] = useState(false);
  const [showHowToPlay, setShowHowToPlay] = useState(true);

  const handleSendMessage = async (inputValue, setInputValue) => {
    if (inputValue.trim()) {

        var allowCensoring = await localStorage.getItem('allowCensoring') || null;
        console.log('allowCensoring: ' + allowCensoring);
        sendMessage(roomID, { /*userName: 'NAME', */userID: userID, content: allowCensoring == 'true' ? CensorBadWords(inputValue) : inputValue, timestamp: Date.now()}, !isOtherPlayer)
        setInputValue("");
        await new Promise(r => setTimeout(r, 100));
        setInputValue("");
    }
  };

  useEffect(() => {

    /*var test =generateInitMessage();

    console.log(test.startMessage);
    console.log(test.message);
    console.log(test.code);*/

    //loadAds();
    const fetchSettingsFromDB = async () => {
      //console.log('fetchSettings');
      var data = await fetchSettings();
      //console.log(data);
      setGlobalMessage(data.globalMessage);

      if(data.allowCensoring)
        localStorage.setItem('allowCensoring', 'true');
      else
        localStorage.setItem('allowCensoring', 'false');
      
    }
    fetchSettingsFromDB();

    const fetchPersonalizedAdsQuestion = async () => {
      //from asyncstorage
      var allowPersonalizedAds = await localStorage.getItem('allowPersonalizedAds') || null;
      if(allowPersonalizedAds == null){
        setShowPersonalizedAdsQuestion(true);
      }

      if(allowPersonalizedAds == 'true'){
        setAllowPersonalizedAds(true);
      }
    }
    fetchPersonalizedAdsQuestion();

    /*const id = localStorage.getItem('userID') || 0;
    if(false){//id != 0){ //TODO: remove false!!!!!
      setUserID(id);
      //console.log('userID: ' + id);
    }
    else{
      const newId = Math.floor(Math.random() * 100000);
      localStorage.setItem('userID', newId);
      setUserID(newId);
      //console.log('new userID: ' + newId);
    }*/

    /*const now = new Date();
    var millisTill15 = null
    //if 50%
    //if(Math.random() < 0.5){
      millisTill15 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 18, 1, 0, 0) - now;
      setTestValue('A: ');
      //console.log('A');
    }
    //else{
    //  millisTill15 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 14, 12, 5, 0) - now;
    //  setTestValue('B: ');
    //  //console.log('B');
    //}
    if (millisTill15 < 0) {
          millisTill15 += 86400000; // it's after 10am, try 10am tomorrow.
    }
    //console.log('SETTIMEOUT!' + testValue)

    if (myTimeout) {
      //console.log('clearTimeout')
      clearTimeout(myTimeout);
  }

    myTimeout = setTimeout(function(){ newGame(); //console.log('Game started')}, millisTill15);*/

    
  }, []);

  useEffect(() => {
    if(retrySearch) {
      //console.log('RETRY')
      newGame();
      setRetrySearch(false);
    }
  }, [retrySearch]);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const newGame = async () => {
    //console.log('newGame()')
    setEnterChat(true);
    setStartGame(false);
    setAbort(false);
    setMeDecided(null);
    setOtherDecided(null);
    await fetchRoom(setRoomID, setMessages, setIsMyTurn, userID, setIsOtherPlayer, setMeDecided, setOtherDecided, setStartGame, setAbort, setRetrySearch);
    setStarted(true)
  }

  const showAvailableAsApp = () => {

    return (<div className='App-link' style={{flexDirection: 'column', display: 'flex', paddingBottom: '20px'}}> 
      <span>Available on</span>
      <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'center'}}>
        {showAndroidBadge()}
        {showIOSBadge()}
      </div>
      </div>)

  }

  const showAndroidBadge = () => { 
    return <a href='https://play.google.com/store/apps/details?id=com.humanorbot&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img style={{width: '150px'}} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
  }

  const showIOSBadge = () => {
    return <a href='https://apps.apple.com/us/app/human-or-bot-gpt-chat-game/id6468664855'><img style={{width: '120px', padding: '10px'}} alt='Get it on App Store' src={AppleBadge}/></a>
  }

  const showSelectedMode = () => {
    if(windowSize.width < 961)
      return null;

    return (<div className="radio-container">
      <label>
          <input 
              type="radio"
              value="portrait"
              checked={selectedMode === "portrait"}
              onChange={(e) => setSelectedMode(e.target.value)}
          />
          Portrait mode
      </label>
      <label>
          <input 
              type="radio"
              value="box"
              checked={selectedMode === "box"}
              onChange={(e) => setSelectedMode(e.target.value)}
          />
          Box mode
      </label>
      <label>
          <input 
              type="radio"
              value="landscape"
              checked={selectedMode === "landscape"}
              onChange={(e) => setSelectedMode(e.target.value)}
          />
          Landscape mode
      </label>
    </div>)
  }

  /*const personilzedAds = async (allow) => {
    setShowPersonalizedAdsQuestion(false);
    if(allow){
      localStorage.setItem('allowPersonalizedAds', 'true');
    }else{
      localStorage.setItem('allowPersonalizedAds', 'false');
    }
  }*/



  function loadAds() {
    // if 50%
    if(Math.random() < 0.5){
      console.log('loadAds()')
      var script = document.createElement('script');
      script.src = 'https://alwingulla.com/88/tag.min.js';
      script.dataset.zone = 17521;
      script.async = true;
      script.dataset.cfasync = 'false';
      script.className = 'alwin-gulla-script'; // Eindeutige Klasse für das Identifizieren
      document.head.appendChild(script);
    }
   // else{



  }
/*
  function unloadAds() {
    console.log('unloadAds()')
    var script = document.querySelector('.alwin-gulla-script');
    if (script) {
        script.remove();
    } else {
        console.error("Kein Skript mit der Klasse 'alwin-gulla-script' gefunden.");
    }
    // Beispiel: Entfernen aller Elemente mit einer bestimmten Klasse
    var adElements = document.querySelectorAll('.hoaj'); // Ersetzen Sie '.ad-class' durch die tatsächliche Klasse der Werbeelemente
    adElements.forEach(function(el) {
        el.remove();
    });

  }

  function removeAllExceptRoot() {

    unloadAds()

      var rootElement = document.getElementById('root');
  
      if (!rootElement) {
          console.error("Element mit ID 'root' wurde nicht gefunden.");
          return;
      }
  
      // Alle Elemente im 'body' durchgehen, außer 'root'
      Array.from(document.body.children).forEach(element => {
          if (element !== rootElement) {
              console.log(element); // Logge das Element
              element.remove(); // Entferne das Element
          }
      });
  }
  

*/



  if(showHowToPlay){
    return (
    <div className="Screen">
      <div className={"App-"+selectedMode+"-mode"}>
      <h1>Human or Bot?</h1>
      <h2>Game Objective</h2>
      <p>Find out if you are chatting with a human or an AI-bot in the "Human or Bot" game.</p>
      <h2>How to Play</h2>
      <p>Engage in a two-minute chat and decide whether your chat partner is a human or an AI-bot. Make your choice and discover if you are right!</p>
      <h2>Game Rules</h2>
      <p>A correct guess results in a win, while an incorrect guess means you lose. Communication is the key to success!</p>
      <button className="App-button" onClick={() => setShowHowToPlay(false)}>Start game</button>
      </div>
    </div>
    )

  }else if(!enterChat)
    return (
      <div className="Screen">
        {/* <AdSenseAd /> */}
      {/*<PropellerAds zoneId="17521" />*/}
        <div className={"App-"+selectedMode+"-mode"}>
          <div className='App-div'>
            <TypingText />
          </div>
          <div className='App-div-column'>
            <button className="App-button" onClick={() =>newGame()}>Start game</button>
            {/* globalMessage != '' ? <a className="App-global-message">{globalMessage.replace(/\\n/g,'\n')}</a> : null*/}
          </div>
          {showAvailableAsApp()}
          {showSelectedMode()}
          <div className="Bottom">
            <a className="App-link" href="/about">About</a>
          </div>
        </div>
        {/* <AdSenseAd /> */}
      </div>
    )
  else
    return (
      <div className="Screen">
        {/* <AdSenseAd /> */}
        <div className={"App-"+selectedMode+"-mode"}>
          <Chat messages={messages} setMessages={setMessages} handleSendMessage={handleSendMessage} started={started} setStarted={setStarted} roomID={roomID} userID={userID} isMyTurn={isMyTurn} isOtherPlayer={isOtherPlayer} meDecided={meDecided} otherDecided={otherDecided} newGame={newGame} startGame={startGame} abort={abort} setMeDecided={setMeDecided}/>
        </div>
        {/* <AdSenseAd /> */}
      </div>
    )
}

export default Home;