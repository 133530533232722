import React from 'react';
import './Message.css'; 

function NewGameMessage({startNewGame, finished, leaveOrAbort}) {

    const buttonText = 'New game'
    var scoreMessage = 'You won ' + (localStorage.getItem('score') || 0) + ' out of ' + (localStorage.getItem('numGames') || 0) + ' games';
    return (
        <div className={`messages-list-item`}>
            <div className={`message message-note`}>
                <div className="message-note-text">
                    <button className="message-decide-button"  onClick={() => startNewGame()}>{buttonText}</button>
                </div>
            </div>
            {
                finished  && !leaveOrAbort  ? (
                <div className={`message message-note`}>
                    <div className="message-note-text">
                        {scoreMessage}
                    </div>
                </div>) : null
            }
        </div>
    );
}



export default NewGameMessage;
