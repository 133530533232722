import React from 'react';
import './Header.css';
import profileImage from './profile.png';
import ReportIcon from '@mui/icons-material/Report';
import { sendReport } from './firebase.js';

function Header({ conversationProgress, roomID, isOtherPlayer, userID, sendLeave, started }) {
  const reportConversation = () => {
    sendReport(roomID, !isOtherPlayer, userID);
    //sendLeave(roomID, !isOtherPlayer); // Probleme
  };

  const showAlert = () => {
    if(started) {
      const userResponse = window.confirm("Do you want to report this conversation?");
      if (userResponse) {
        reportConversation();
      }
    }
  };
  
  const time = new Date(120000 - (120000 * conversationProgress / 100)).toISOString().substr(14, 5);
  var name = "Human or Bot?";

  return (
    <div className="chat-header">
      <div className="profile-image-container">
        <img src={profileImage} alt="Profil" className="profile-image" />
      </div>
      <span className="chat-name">{name}</span>
      <span className="chat-time">{time}</span>
      <button onClick={showAlert} className="report-button">
        <ReportIcon style={{ color: started ? "white" : "grey", fontSize: 25 }} />
      </button>
    </div>
  );
}

export default Header;
