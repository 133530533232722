import React from 'react';
import { sendSelected } from '../firebase';
import './Message.css';


import botNeutral from '../botNeutral.png';
import humanNeutral from '../humanNeutral.png';

function DecideMessage({roomID, userID, setMeDecided, isOtherPlayer, leaveOrAbort}) {

    function increaseScore(increase){

        if(leaveOrAbort){
            //console.log('game dont count');
            return;
        }


        var numGames = localStorage.getItem('numGames') || 0;
        numGames++;
        localStorage.setItem('numGames', numGames);

        if(increase){
            var score = localStorage.getItem('score') || 0;
            score++;
            localStorage.setItem('score', score);
        }
    }

    var note = 'Who did you talk you?';
    return (
        <div className={`message message-note`}>
            <div className="message-note-text">
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    {note}
                    <div className="message-decide">
                        <button className="message-decide-button" onClick={() => { sendSelected(roomID, userID, 'human', !isOtherPlayer); setMeDecided('human'); increaseScore(isOtherPlayer)}}><img src={humanNeutral} alt="Bot Icon" style={{ height:20, width: 20, verticalAlign: '-5px' }} /> Human</button>
                        <button className="message-decide-button" onClick={() => { sendSelected(roomID, userID, 'bot', !isOtherPlayer); setMeDecided('bot'); increaseScore(!isOtherPlayer)}}><img src={botNeutral} alt="Bot Icon" style={{ height:20, width: 20, verticalAlign: '-5px' }} /> Bot</button>
                    </div>
                </div>
            </div>
        </div>
    );
}



export default DecideMessage;
