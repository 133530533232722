import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './Home';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
    <App />
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


function App() {
  return (
    <Router>
      <div className="Page">
        <header className="App-header">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/privacyterms" element={<PrivacyTerms />} />
            <Route path="/termsofuse" element={<TermsOfUse />} />
            <Route path="/contactus" element={<ContactUs />} />
            {/* Weiterleitungen von alten Pfaden zu neuen Pfaden */}
            <Route path="/old-path" element={<Navigate to="/new-path" replace />} />
          </Routes>
        </header>
      </div>
    </Router>
  );
}

const PrivacyTerms = () => {
  var policy = `Privacy Policy

  1. Introduction
  
  Welcome to "Human or Bot". This website is owned and operated by Stefan Tissen. We respect your privacy and are committed to protecting your personal data. This Privacy Policy will inform you as to how we look after your personal data when you visit our website and tell you about your privacy rights and how the law protects you.
  
  2. Personal Data We Collect
  
  We collect the text of the chat conversations that occur on our website. Our server functionalities are powered by Firebase, which may collect additional information. Please refer to Firebase’s Privacy Policy for more information on what they collect.
  
  3. Use of Your Personal Data
  
  We use the collected data to improve the game and prevent misuse of the game.
  
  4. Sharing of Your Personal Data
  
  We share the collected data with AdMob, a third-party advertising company, to serve ads when you visit our website. Please refer to AdMob’s Privacy Policy for more information on how they use your data. In addition to AdMob, we may also share your data with other advertisers or third parties who place advertisements on our website.
  
  5. Data Security
  
  We use the data security provided by Firebase to ensure the security of the collected data.
  
  6. Data Retention
  
  We will retain the collected data as long as the website is used.
  
  7. Your Rights
  
  You have the right to access, correct, or delete your personal data. Please contact us at humanorbot.team@gmail.com if you wish to exercise any of these rights.
  
  8. Cookies
  
  We use cookies and similar technologies to show ads on our website. These cookies may also be used by other advertisers or third parties to provide targeted advertising on our website.
  
  9. Changes to This Privacy Policy
  
  We may change this Privacy Policy at any time without notice. It is your responsibility to check for updates to this Privacy Policy.

  10. Limitation of Liability

  We are not liable for any damages arising from the use of our website or the advertisements displayed on it. The use of our website is at your own risk. We are not responsible for the content provided by third-party advertisers or service providers.

  11. Applicability to Other Advertisers

  This Privacy Policy is applicable not only to AdMob but also to other advertisers or third parties who place advertisements on our website. We may share your data with these parties to enable personalized advertising. Please refer to the privacy policies of these third parties to understand how they handle your data.
  
  12. Contact Us

  If you have any questions about this Privacy Policy, please contact us at humanorbot.team@gmail.com.`
  return (<div className='Policy' style={{ whiteSpace: 'pre-line' }}>
    {policy}
  </div>)
};

const TermsOfUse = () => {
  var policy = `Terms of Use

  1. Introduction
  
  Welcome to "Human or Bot". This website is owned and operated by Stefan Tissen. By accessing and using this website, you agree to abide by these Terms of Use. If you do not agree with these terms, please do not access or use the website.
  
  2. Age Restrictions
  
  You must be at least 16 years old to use this website.
  
  3. Use of the Website
  
  This website provides a game where you can chat for 2 minutes with a human or an AI, and then decide if you were chatting with a human or an AI. If you decide correctly, you win. You are responsible for your own actions while using this website and you should remain respectful at all times.
  
  4. User Data
  
  We use Firebase for server-related functionalities and collect data from the chat conversations. Apart from this, no other personal information is collected. We may use third-party advertising companies to serve ads when you visit our website. These companies may use information about your visits to this and other websites in order to provide advertisements about goods and services of interest to you.
  
  5. Intellectual Property
  
  All intellectual property rights related to the website, including its name "Human or Bot", are owned by us.
  
  6. Limitation of Liability
  
  You agree that you are using this website at your own risk and that we are not liable for any damages or losses resulting from your use of the website.
  
  7. Termination of Use
  
  We reserve the right to terminate your access to the website at any time and for any reason, including but not limited to, misuse of the game.
  
  8. Dispute Resolution
  
  Any disputes arising out of or related to these Terms of Use or your use of the website shall be resolved through arbitration.
  
  9. Changes to Terms
  
  We may change these Terms of Use at any time without notice. It is your responsibility to check for updates to these terms.`
  return (<div className='Policy' style={{ whiteSpace: 'pre-line' }}>
    {policy}
  </div>)
};

const About = () => {

  // return 3 links (column), "Privacy Policy", "Terms of Use", "Contact Us"
  return (
    <div className='About'>
      <div className='AboutColumn'>
        <Link to='/privacyterms' className='AboutLink'>Privacy Policy</Link>
        <Link to='/termsofuse' className='AboutLink'>Terms of Use</Link>
        <Link to='/contactus' className='AboutLink'>Contact us</Link>
      </div>
    </div>
  )
};

const ContactUs = () => {
  var contactus = `Contact Us
  
  We value your questions, feedback, and suggestions. Whether you have a query about our products/services, need assistance, or want to offer feedback, please get in touch with us.
  
  Address:
  Stefan Tissen
  Berliner Str. 40
  66849 Landstuhl
  
  Email:
  humanorbot.team@gmail.com`
  return (<div className='Policy' style={{ whiteSpace: 'pre-line' }}>
    {contactus}
  </div>)
};

